import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {PORTAL} from "@app/core/constants/routes";

import {AuthGuard, CanLoadGuard} from "@app/core/guards";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./public-layout/public-layout.module')
      .then(m => m.PublicLayoutModule)
      .catch(),
  },
  {
    path: PORTAL.replace('/', ''),
    loadChildren: () => import('./layout/layout.module')
      .then(m => m.LayoutModule)
      .catch(),
    canLoad: [CanLoadGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canLoad: [AuthGuard]
  },
  {
    path: '**', redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  providers: [
    AuthGuard,
    CanLoadGuard,
  ]
})
export class AppRoutingModule { }
