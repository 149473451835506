export const LANDING = '/';
export const PORTAL = '/portal';
export const DATASETS = `${PORTAL}/datasets`;
export const DATASETS_MAP = `${PORTAL}/datasetsmap`;
export const PROJECTS = `${PORTAL}/projects`;
export const PUBLIC_DATASETS = `${PORTAL}/public-datasets`;
export const PUBLIC_PROJECTS = `${PORTAL}/public-projects`;
export const HOME = PROJECTS;

// User Account & Auth Routes
export const AUTH = 'auth';
export const LOGIN_BASE_LINK = 'login';
export const ACCOUNT = `${PORTAL}/account`;
export const REGISTER_BASE_LINK = 'register';
export const USER_BALANCE = `${ACCOUNT}/balance`;
export const USER_SPENDINGS = `${ACCOUNT}/spendings`;
export const USER_EARNINGS = `${ACCOUNT}/earnings`;
export const USER_PROFILE = `${ACCOUNT}/profile`;
export const RESET_PASSWORD_BASE_LINK = 'reset-password';
export const FORGOT_PASSWORD_BASE_LINK = 'forgot-password';
export const LOGIN = `${AUTH}/${LOGIN_BASE_LINK}`;
export const REGISTER = `${AUTH}/${REGISTER_BASE_LINK}`;
export const RESET_PASSWORD = `${AUTH}/${RESET_PASSWORD_BASE_LINK}`;
export const FORGOT_PASSWORD = `${AUTH}/${FORGOT_PASSWORD_BASE_LINK}`;

export const ANNOTATION = `${DATASETS}/{id}/annotation`;
export const ANNOTATION_MAP = `${DATASETS_MAP}/{id}/annotation`;

// PUBLIC ROUTES
export const FAQ: string = 'faq';
export const BLOG: string = 'blog';
export const ABOUT: string = 'about';
export const PRICING: string = 'pricing';
export const CONTACT: string = 'contact';
export const PRODUCT: string = 'product';
export const PRIVACY_POLICY: string = 'privacy-policy';
export const TERMS_CONDITIONS: string = 'terms-conditions';
export const DATASET_DETAIL: string = `${LANDING}dataset-detail`;

export const PROJECT_DATASET_INFERENCE: string = `${PROJECTS}/:id/inference`;
export const PROJECT_BATCH_INFERENCE: string = `${PROJECTS}/:id/batch-inference`;
export const PROJECT_INFERENCE_DETECT: string = `${PROJECT_DATASET_INFERENCE}/detect`;
