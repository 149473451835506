import {Injectable} from "@angular/core";
import {DataUrl, DOC_ORIENTATION, NgxImageCompressService} from "ngx-image-compress";

import {HelperService} from "@app/core/services";

@Injectable({
  providedIn: 'root'
})

export class ImageCompressService {
  public finalImage !: File;
  public finalBase64String !: DataUrl;

  constructor(
    private readonly _imageCompress: NgxImageCompressService,
    private readonly _helperService: HelperService,
  ) {}

  compressImage(image: File | DataUrl, ratio: number = 50, quality: 50, maxWidth: number = 0, maxHeight: number = 0): Promise<DataUrl | File> {
    return new Promise<DataUrl | File>(resolve => {
      typeof image === 'string'
        ? this.compressBase64String(image, ratio, quality, maxWidth, maxHeight)
          .then((dataUrl: DataUrl) => {
            return resolve(dataUrl);
          })
        : this.compressImageFile(image, ratio, quality, maxWidth, maxHeight)
          .then((file: File) => {
            return resolve(file);
          });
    })
  }

  compressImageFile(image: File, ratio: number = 50, quality: 50, maxWidth: number = 0, maxHeight: number = 0): Promise<File> {
    return new Promise<File>(resolve => {
      this._helperService.imageFileToDataURI(image, (dataUrl: DataUrl) => {
        this._imageCompress.getOrientation(image)
          .then((orientation: DOC_ORIENTATION) => {
            this._imageCompress.compressFile(dataUrl, orientation, ratio, quality, maxWidth, maxHeight)
              .then((result: DataUrl) => {
                this.finalBase64String = result;
                this.finalImage = this.dataUrlToImageFile(result, image.name, image.type);
                return resolve(this.finalImage)
              });
          });
      });
    });
  }

  compressBase64String(base64Str: DataUrl, ratio: number = 50, quality: 50, maxWidth: number = 0, maxHeight: number = 0): Promise<DataUrl> {
    return new Promise<DataUrl>(resolve => {
      this._imageCompress.compressFile(base64Str, DOC_ORIENTATION.Default, ratio, quality, maxWidth, maxHeight)
        .then((result: DataUrl) => {
          this.finalBase64String = result;
          return resolve(result);
        });
    });
  }

  dataUrlToImageFile(dataUrl: DataUrl, name: string, type: string): File {
    const blob: Blob = this.dataUrlToBlob(dataUrl, name, type);
    return new File([blob], name, {type: type});
  }

  dataUrlToBlob(dataUrl: DataUrl, name: string, type: string): Blob {
    const blob: any = this._helperService.dataURItoBlob(dataUrl);
    blob.name = name;
    blob.lastModifiedDate = new Date();
    return blob;
  }
}
