import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

import { MetaTags } from '../constants/metaTags';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {
  public defaultTag = {
    h1: null,
    author: 'Thya Technology',
    keywords: 'Thya Technology',
    title: 'Thya Technology',
    description: 'Thya Technology',
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private meta: Meta,
    private title: Title
  ) { }

  add(obj: any): void {
    const tag = this.findTag(obj);
    this.title.setTitle(tag.title || this.defaultTag.title);
    this.meta.addTag({ name: 'author', content: tag.author || this.defaultTag.author });
    if (this.isAllowMetaTags()) {
      this.meta.updateTag({ name: 'keywords', content: tag.keywords || this.defaultTag.keywords });
      this.meta.updateTag({ name: 'description', content: tag.description || this.defaultTag.description });
    }
  }

  findTag(obj: any): any {
    if (!obj && !obj.url) {
      return this.defaultTag;
    }
    const url = obj.url, getTag = MetaTags.find((tag: any) => tag.url === url);
    return getTag || this.defaultTag;
  }

  setDefaultTags(): void {
    this.title.setTitle(this.defaultTag.title);
    this.meta.addTag({ name: 'author', content: this.defaultTag.author });
    if (this.isAllowMetaTags()) {
      this.meta.addTag({ name: 'keywords', content: this.defaultTag.keywords });
      this.meta.addTag({ name: 'description', content: this.defaultTag.description });
    }
  }

  setTitle(title: any = this.defaultTag.title): void {
    this.title.setTitle(title);
  }

  setSocialMetaTags(tag: any = {}): void {
    if (tag.title) {
      this.defaultTag.h1 = tag.title;
    }
    if (this.isAllowMetaTags()) {
      const tagList: any[] = [
        { name: 'public', content: 'public' },
        { name: 'og:type', content: 'website' },
        { name: 'robots', content: 'all' },
        { name: 'language', content: 'English, Arabic' },
        { name: 'og:site_name', content: this.defaultTag.title },
        { name: 'twitter:site', content: this.defaultTag.title },
        { name: 'twitter:card', content: tag.card || 'summary' },
        { name: 'og:title', content: tag.title || this.getPageTitle() },
        { name: 'twitter:title', content: tag.title || this.getPageTitle() },
        { name: 'abstract', content: tag.slug || tag.title || this.getPageTitle() },
        { name: 'og:description', content: tag.description || this.defaultTag.description },
        { name: 'twitter:description', content: tag.description || this.defaultTag.description },
      ];
      if (this.document.location.href) {
        const url = this.document.location.href;
        tagList.push(
          { name: 'canonical', content: url },
          { name: 'og:url', content: url },
        );
      }
      if (tag.image) {
        tagList.push(
          { name: 'og:image', content: tag.image },
          { name: 'twitter:image', content: tag.image },
        );
      }
      if (tag.imageAlt) {
        tagList.push(
          { name: 'og:image:alt', content: tag.imageAlt },
          { name: 'twitter:image:alt', content: tag.imageAlt },
        );
      }
      if (tag.keywords) {
        this.meta.updateTag({
          name: 'keywords',
          content: tag.keywords
        });
      }
      if (tag.description) {
        this.meta.updateTag({
          name: 'description',
          content: tag.description
        });
      }
      if (tag.author) {
        this.meta.updateTag({
          name: 'twitter:creator',
          content: tag.author
        });
        this.meta.updateTag({
          name: 'author',
          content: tag.author
        });
      }
      if (tag.authorId) {
        this.meta.updateTag({
          name: 'twitter:creator:id',
          content: tag.authorId
        });
      }
      this.meta.addTags(tagList);
    }
  }

  getPageTitle(): string {
    return this.defaultTag.h1 || this.title.getTitle();
  }

  /**
   * Following method will generate the canonical for the respective page
   */
  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', this.document.URL);
  }

  public isAllowMetaTags(): boolean {
    return true;
  }
}
