import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from "rxjs/operators";
import {ActivatedRoute, CanLoad, NavigationExtras, Route, Router, UrlSegment, UrlTree} from '@angular/router';

import {AuthService} from '../services';

@Injectable()
export class CanLoadGuard implements CanLoad, OnDestroy {
  private _redirectTo: string | null = null;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this._onDestroy$)
      )
      .subscribe(params => {
        if (params['redirectTo']) {
          this._redirectTo = params['redirectTo'];
        }
      });
  }

  /**
   * The following checks if the access can be provided or not to the current user
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectLink: string = this._redirectTo || segments
      .reduce((previousValue, currentValue) => previousValue + '/' + currentValue.path, '');
    const extras: NavigationExtras = {queryParams: {redirectTo: redirectLink}, queryParamsHandling: "merge"};
    if (this.authService.isServer) {
      return this.goToRouteWithExtras('/', extras);
    } else if (this.authService.isLoggedIn()) {
      return true;
    }
    return this.goToRouteWithExtras('/auth/login', extras);
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private goToRouteWithExtras(url: string, extras: NavigationExtras): Promise<boolean> {
    return this.router.navigate([url], extras)
      .then()
      .catch()
  }
}
