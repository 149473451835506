import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as CONST_LIST from '../constants/constant-list';
import * as ROUTE_LIST from '../constants/routes';
import * as API_LIST from '../constants/apis-list';
import {HttpService, HelperService} from '../services';


/*
 * Base Resolver
 * Top Level Resolver
 */
@Injectable()
export class BaseResolver {
  protected router: Router;
  protected route: ActivatedRoute;
  public httpService: HttpService;
  public helperService: HelperService;

  public constantList = CONST_LIST;
  public routeList = ROUTE_LIST;
  public apiList = API_LIST;

  constructor(injector: Injector) {
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.httpService = injector.get(HttpService);
    this.helperService = injector.get(HelperService);
  }

  public redirectToLink(url?: string) {
    this.router.navigate([url || ''])
      .then()
      .catch();
  }
}
