import { Injectable } from '@angular/core';
import hexToRgba from 'hex-to-rgba';
declare const Buffer: any;

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  constructor() { }

  public uploadMedia(event: any): any {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files.length) {
        const media = event.target.files[0];
        const data: any = {
          src: '',
          formData: media,
          type: media.type,
          name: media.name,
        };
        if (media.type.indexOf('image') === -1) {
          data.src = data.formData;
          resolve(data);
        } else {
          const reader = new FileReader();
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          reader.onload = () => {
            data.src = reader.result as string;
            resolve(data);
          };
        }
      } else {
        reject();
      }
    });
  }

  /**
  * The following method is used to generate random colors for Inference and Batch-Inference 
  * @returns {Blob}
  */

  public getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  /**
 * The following method is used to generate random RGBA colors object
 * @returns {Blob}
 */

  public getRandomRGBAColor() {
    let o = Math.round, r = Math.random, s = 255;
    return { r: +o(r() * s), g: +o(r() * s), b: +o(r() * s), a: +r().toFixed(1) };
  }

  public getHexToRGBAObj(color: string): any {
    if (color) {
      const rgba = hexToRgba(color).replace('rgba(', '').replace(')', '').split(',');
      return { r: +rgba[0], g: +rgba[1], b: +rgba[2], a: +rgba[3] };
    }
    return this.getRandomColor();
  }


  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param dataURI
   * @returns {Blob}
   */
  public dataURItoBlob(dataURI: any): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = this.getMimetypeFromDataUri(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(ab);
    return new Blob([dataView], { type: mimeString });
  }

  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param unit8Array
   * @param type
   * @returns {Blob}
   */
  public Unit8ArrayToFile(unit8Array: any, type: string = ''): Blob {
    const byteString = atob(unit8Array.toString('base64'));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type });
  }

  /**
   * the following makes sure the given string is not null or empty
   * @param {string} value
   * @returns {boolean}
   */
  public isNotEmptyOrUndefined(value: any) {
    return (value !== undefined || value !== '' || value.length > 0);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param imageFile
   * @param callback
   */
  public imageFileToDataURI(imageFile: any, callback: any) {
    const file: File = imageFile,
      myReader: any = new FileReader();

    myReader.onloadend = (e: any) => {
      /*const trimIndex = myReader?.result.toString().indexOf('base64,');
      callback(myReader?.result.toString().substr(trimIndex + 7));*/
      callback(myReader?.result.toString());
    };

    myReader.readAsDataURL(file);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param base64Img
   * @param callback
   */
  public formatBase64Image(base64Img: string, callback: any) {
    const img = base64Img;
    const trimIndex = img.indexOf('base64,');
    callback(img.substr(trimIndex + 7));
  }

  /**
   * The following method is used to check the dimensions of image
   * @param imageFile
   * @param callback
   */
  public imageFileDimensions(imageFile: any, callback: any) {
    const fReader: any = new FileReader();
    fReader.onload = function () {
      const img = new Image;
      img.onload = function () {
        callback(img.width, img.height);
      };

      img.src = fReader.result.toString();
    };

    fReader.readAsDataURL(imageFile);
  }

  /**
   * The following checks if the given is a valid base64 string or not
   * @param {string} base64Img
   * @returns {boolean}
   */
  public isValidBase64(base64Img: string) {
    try {
      atob(base64Img);
      return true;
    } catch (e) {
      return false;
    }
  }

  public generateSeoFriendlyUrl(link: string, name: any, id: number | string): string {
    const slugName = name ? this.formatString(name).toLowerCase() : null;
    if (id) {
      return `${link}/${slugName ? slugName + '-' + id : id}`;
    }
    return `${link}/${slugName ? slugName : ''}`;
  }

  public getIdFromSeoFriendlyUrl(link: string | null): any {
    if (!link) {
      return null;
    }
    const numberRegex = new RegExp(/^\d+$/);
    // Return if link is already an id
    if (numberRegex.test(link)) {
      return Number(link);
    }
    const id = link.split(/[-]+/).pop();
    return id && numberRegex.test(id) ? Number(id) : null;
  }

  public getMimetypeFromDataUri(dataURI: string): string {
    return dataURI.split(',')[0].split(':')[1].split(';')[0];
  }

  public formatString(str: string = ''): string {
    return `${str}`.replace(/[^a-z0-9_]+/gi, '-')
      .replace(/^-|-$/g, '');
  }

  getNestedPropertyValue(
    theObject: any,
    path: string,
    separator = '.'
  ): string {
    try {
      separator = separator || '.';
      const pathsList = path
        .replace('[', separator)
        .replace(']', '')
        .split(separator);
      return pathsList.reduce((obj, property, index) => {
        // Checking for array
        if (property.indexOf('*') > -1) {
          if (Array.isArray(obj)) {
            return obj || [];
          }
          return (obj[pathsList[index - 1]] || []).map((i: any) => {
            return i[pathsList[index + 1]];
          });
        }
        if (Array.isArray(obj)) {
          return (obj).map((i: any) => {
            return i[property];
          });
        }
        return obj[property];
      }, theObject);
    } catch (err) {
      return '';
    }
  }

  public getImageDimensions(image: File, cb: Function) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      const img = new Image();
      img.onload = function () {// image is loaded; sizes are available
        cb({
          width: img.width,
          height: img.height
        });
      };
      img.src = reader.result as string; // is the data URL because called with readAsDataURL
    };
  }

  /**
   * Following method will convert base64 to File
   * @param dataurl 
   * @param filename 
   * @returns 
   */
  public dataURLtoFile(dataurl: any, filename: any) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Following method will remove requested props and return a new object
   * @param obj 
   * @param keys 
   * @returns 
   */
  public objectWithoutProperties(obj: any, keys: string[]) {
    const target: any = {};
    for (var i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  }
  public parseFileText(geoJson: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = reader.result as string; // The file content as string
        try {
          const result = JSON.parse(fileContent);
          resolve(result);
        } catch (error: any) {
          reject(new Error(`Invalid JSON format: ${error.message}. File content: ${fileContent}`));
        }
      };
      reader.onerror = (event) => {
        reject(new Error("Error reading file"));
      };
      reader.readAsText(geoJson);
    });
  }

  public getKeysOfObject(obj: any): string[] {
    if(!obj) return [];
    return Object.keys(obj);
  }
}
