// Http Headers Constants
export const TOKEN = 'kaust-customer-token'
export const DEFAULT_ACCESS_DENIED_CODE = 'access_denied';
export const DEFAULT_ACCESS_DENIED_MESSAGE = `You don't have permission to perform the required action.`;
export const DEFAULT_ERROR_MESSAGE = 'Server rejected your request. Please try again!';
export const ENCRYPTION_SECRET = 'iA9iU1xE1wA4bO3xJ8eL0tW9aG1lF2vF';
export const DEFAULT_INVALID_TOKEN_SERVER_RESPONSE = 'Token not provided';
export const DEFAULT_INVALID_TOKEN_SIGNATURE_SERVER_RESPONSE = 'Token Signature could not be verified.';
export const CONFIRM_LEAVE_CHANGES_MSG: string = 'Are you sure you want to leave the screen without saving the changes?';
export const CONFIRM_LEAVE_PREDICT_MSG: string = 'Are you sure to leave the screen? Make sure to export your results if you need a copy of them.';
export const MD = 991;
export const MDX = 769;
export const SM = 578;

export const PLACEHOLDER_IMG: string = '/assets/images/img-placholder.svg';
export const PLACEHOLDER_IMG_BG: string = '/assets/images/placeholder-blog.jpg';

export const SUCCESS_STATUS: any = 200;
export const REQUEST_CONFLICT_STATUS: any = 409;

export const SEARCH = "search";
export const CREATED_BY = "createdBy";
export const UPDATED_BY = "updatedAt";
export const DEFAULT_SORT_KEY = "id";
export const DATASETS_IMAGES_SORTING = 'dataset_images.id';
export const DEFAULT_SORT_ORDER = "DESC";
export const NUMBER_RECORDS_PER_PAGE = 20;
export const DEFAULT_PAGE_INDEX = 1;
export const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];

export const DEFAULT_DEBOUNCE_TIME = 1000;

export const PERMISSION_READ: string = 'read';
export const ROLE_SUPER_ADMIN: string = 'super_admin';
export const ROLE_GLOBAL_ADMIN: string = 'global_admin';
export const ROLE_ADMIN: string = 'admin';
export const ROLES: any[] = [
  {
    title: 'Super Admin',
    value: ROLE_SUPER_ADMIN,
  }, {
    title: 'Global Admin',
    value: ROLE_GLOBAL_ADMIN,
  }, {
    title: 'Admin',
    value: ROLE_ADMIN,
  }, {
    title: 'Content Manager',
    value: 'content_manager',
  }, {
    title: 'Account Manager',
    value: 'account_manager',
  }, {
    title: 'Data Scientist',
    value: 'data_scientist',
  }, {
    title: 'Data Analyst',
    value: 'data_analyst',
  },
  {
    title: 'Customer',
    value: 'customer',
  }
];

export const WalletUsageType: any = {
  topup: 'Top Up',
  inference: 'Inference',
  training: 'Training',
  cloning: 'Cloning',
  earning: 'Earning',
  cashout: 'Cashout',
  free_bundle: 'Free Bundle',
  pro_bundle: 'Pro Bundle',
  custom_bundle: 'Custom Bundle',
  purchased: 'Purchased',
  wrongly_detected_image: 'Wrongly detected image',
  cancel_inference: 'Canceled Batch Inference'
}

export const amazonPayResponses: any = {
  success: {
    message: 'Congratulations! Your payment is complete and Credits will be added to your Wallet.',
    status: 'success'
  },
  failed: {
    message: "We're sorry, but there was an issue processing your payment. Please double-check your payment details and try again. If the problem persists, we advise that you contact your bank, then our support team for assistance.",
    status: 'error'
  },
  canceled: {
    message: 'Transaction has been cancelled by the Consumer.',
    status: 'warning'
  }
}
export const TERMS_CONDITIONS: string = 'terms_conditions';
export const CONFIRM_MESSAGE_FOR_UNSAVED_IMAGES_OTHER_DATASET: string = "You have already paused images in another dataset. If you add more images, the paused images will be deleted. Are you sure you want to proceed?"
export const CONFIRM_MESSAGE_FOR_UNSAVED_IMAGES_CREATE_DATASET: string = "You have already paused images in another dataset. If you create a new dataset, the paused images will be deleted. Are you sure you want to proceed?"
export const CONFIRM_MESSAGE_FOR_UNSAVED_IMAGES_PAUSE_DELETE_DATASET: string = "You have already paused images in the dataset. If you delete the dataset, the paused images will be deleted. Are you sure you want to proceed?";
export const CONFIRM_MESSAGE_FOR_UNSAVED_IMAGES_UPLOADING_DELETE_DATASET: string = "You are already uploading images in this dataset. If you delete the dataset, uploading images will be deleted. Are you sure you want to proceed?";


// Annotation Toolkit constant
export const MIN_ZOOM: number = 1;
export const MAX_ZOOM: number = 4;
export const ZOOM_STEP: number = 0.1;
export const EDITOR_MIN_WIDTH: number = 540;
export const EDITOR_MIN_HEIGHT: number = 300;
export const EDITOR_LEFT_TOOLBAR_WIDTH_PX: number = 60;
export const SIDE_LABELS_BAR_WIDTH_PX: number = 325 + 1;
export const EDITOR_CONTAINER_TOP_MARGIN_PX: number = 10;
export const EDITOR_CONTAINER_INNER_LEFT_MARGIN_PX: number = 15;
export const EDITOR_CONTAINER_INNER_RIGHT_MARGIN_PX: number = 15;
export const TOOLKIT_PAGE_NUMBER_HEIGHT_PX: number = 40;
export const EDITOR_TOP_NAVIGATION_LABEL_HEADER_PX: number = 40 + 1;
export const EDITOR_TOP_NAVIGATION_BAR_HEIGHT_PX: number = 60;
export const EDITOR_BOTTOM_IMAGE_SLIDER_HEIGHT_PX: number = 60;
export const TOOLKIT_SLIDER_TOP_MARGIN_PX: number = 10;
export const TOOLKIT_SLIDER_BOTTOM_MARGIN_PX: number = 10;

// Inference & Dataset Image constant
export const DATASET_IMAGE_COUNT_KEY: string = 'kaust-customer-dataset-image-count';
export const INFERENCE_PROJECT_IMAGE_LIST_KEY: string = 'kaust-customer-inference-project-images';
export const DATASET_UPLOADED_IMAGE_COUNT_KEY: string = 'kaust-customer-dataset-uploaded-image-count';
export const DATASET_ID_BACKGROUND_IMAGE_KEY: string = 'kaust-customer-dataset-id-for-image-uploading';
export const INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY: string = 'kaust-customer-inference-image-uploading';
export const BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY: string = 'kaust-customer-batch-inference-image-uploading';

// App Config
export const APP_CONFIG_KEY: string = 'kaust-customer-app-config';

// Fabric point constant
export const TOOLKIT_SHAPE_POINT_WIDTH: number = 10;
export const TOOLKIT_SHAPE_POINT_HEIGHT: number = 10;
export const nameRegex = /^([^0-9]*)$/;
export const emailRegex = "^[a-z0-9._%+-]{2,}@[a-z0-9.-]{2,}\\.[a-z]{2,4}$";

export const TOOLKIT_OPACITY_MASK: number = 0.4; // 0.8 old mask opacity
export const TOOLKIT_OPACITY_POLYGON: number = 0.4;
export const MSG_UPGRADE_BUNDLE: string = 'Please upgrade your plan as you have reached the limit allowed to you in the current bundle.';
export const MSG_PRIVATE_PROJECTS_DATASETS_LIMIT_EXCEED: string = 'Current amount of Private Datasets/Projects exceeds the allowed limit of the Number of Private Datasets/Projects';
export const GET_CURRENT_TIMEZONE: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

// dataset type
export const DATASET_TYPE_MEDIA = 'image';
export const DATASET_TYPE_MEDIAS = 'images';
export const DATASET_TYPE_MAP = 'map';
export const DEFAULT_LAYER_COLOR = '#3388ff';