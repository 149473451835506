import { Injectable, Injector } from '@angular/core';
import {throwError, BehaviorSubject, Subject} from 'rxjs';
import {map, shareReplay, tap, catchError, takeUntil} from 'rxjs/operators';

import {User} from "@app/core/models/User";
import { HttpService } from "@app/core/services/http.service";

import {trimValue} from "@layout/helpers/trim-value";
import {getLastModifiedImage} from "@app/core/shared/helpers";
import {LOGIN, GET_STORAGE_DETAILS, LOGOUT} from '@app/core/constants/apis-list';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {
  private errors = [];
  public loggedIn = false;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(injector: Injector) {
    super(injector);
    this.setUserDetails();
  }

  setUserDetails(user: any = this.localService.get('kaust-web-user')): void {
    this.user = new User(user || {});
    this.userSubject.next(this.user);
  }

  login(body: { email: any, password: any }) {
    return this.requestEntity(
      'POST',
      LOGIN,
      body,
    )
      .pipe(
        map((response: any) => {
          if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
            if (!response.body) {
              this.showMessage(`User doesn't exists!`, 'warning');
            }
            const role = response.body.role;
            if (!role) {
              this.showMessage('Invalid role', 'warning');
              return null;
            }
            const hasValidRole = role && this.constantList.ROLES.map(r => r.value)
              .indexOf(role) > -1;
            if (!hasValidRole) {
              this.showMessage('Invalid role', 'warning');
              return null;
            } else {
              return response.body;
            }
          }
          this.clearErrors();
          return null;
        }),
        tap(authResult => {
          if (authResult) {
            this.setLocalSession(authResult as any);
            this.loggedIn = true;
            return authResult;
          }
          this.loggedIn = false;
          return null;
        }),
        shareReplay(1),
        catchError(err => throwError(() => this.handleErrorMessages(err)))
      );
  }

  public logout() {
    this.requestEntity(
      'DELETE',
      LOGOUT,
      null,
      this.formDataHeaders,
      false,
    ).pipe(
      takeUntil(this._onDestroy$),
    ).subscribe({
      complete: () => {
        this.setUserDetails(null);
        this.localService.clearDataInLocalStorage();
        this._onDestroy$.next();
        this._onDestroy$.complete();
        window.location.replace('/auth/login');
      }
    });
  }

  public isLoggedIn(): boolean {
    return this.localService.getToken();
  }

  public clearErrors() {
    this.errors = [];
  }

  public updateUserPreference(key: string, value: any): void {
    if (this.user?.id) {
      const preference = {
        id: null,
        key: key,
        value: value,
        userId: this.user.id,
      };
      const preferenceIndex: number = (this.user.preferences || []).findIndex((p: any) => p.key === key);
      if (!this.user.preferences) {
        this.user.preferences = [];
      }
      if (preferenceIndex > -1) {
        if (this.user.preferences[preferenceIndex].id) {
          preference.id = this.user.preferences[preferenceIndex].id;
        }
        this.user.preferences[preferenceIndex] = preference;
      } else {
        this.user.preferences.push(preference);
      }
      this.updateUserDetails();
    }
  }

  /**
   * the following method will store info in the local session
   * @param authResult
   */
  setLocalSession(authResult: any) {
    if (authResult?.profilePicture) {
      authResult.profilePicture = getLastModifiedImage('lastModified', authResult.profilePicture);
    }
    trimValue(authResult.lastName);
    trimValue(authResult.firstName);
    authResult.bookmarkDatasets = this.getMapBookmarkList(authResult.bookmarkDatasets) || [];
    authResult.bookmarkProjects = this.getMapBookmarkList(authResult.bookmarkProjects) || [];
    this.localService.setToken(authResult.token);
    this.localService.set({ key: 'kaust-web-permissions', value: authResult.permissions || [] });
    this.updateUserDetails(authResult)
  }

  /*
  * The following is used to update the bookmark the data in user
  * @author Shahroz Allauddin
  * @param {source} string
  * @param {url} string
  * @param {id} number
  * @param {bookmark} boolean
  * */
  public updateBookmarkBySource(
    source: 'Dataset' | 'Project',
    url: string,
    data: any,
    bookmark: boolean
  ) {
    return this.requestEntity(
      'PATCH',
      url,
      {
        bookmark
      }
    ).pipe(
      map((res: any) => {
        const isUpdated = (res?.status as any) === this.constantList.SUCCESS_STATUS;
        if (isUpdated) {
          this.showMessage(`${source} ${bookmark ? 'Bookmarked' : 'Unbookmarked'} Successfully!`, 'success');
          if (data?.id) {
            data.isFavorite = bookmark;
          }
        } else {
          this.showMessage(res?.messsage || 'Unable to save changes');
        }
        return isUpdated;
      }),
      shareReplay(1),
      catchError(err => throwError(() => this.handleErrorMessages(err)))
    );
  }

  /*
  * The following is used to get the user AWS storage details
  * @author Shahroz Allauddin
  * @param {showMsg} boolean - To display message in toastr service if set to true
  * */
  public getStorageDetails(showMsg: boolean = true) {
    return this.requestEntity(
      'GET',
      GET_STORAGE_DETAILS.replace('{id}', this.user.id),
    ).pipe(
      map((res: any) => {
        if ((res?.status as any) === this.constantList.SUCCESS_STATUS) {
          const data: any = res?.body || null;
          if (data?.storageUsed >= data?.storageAllocated && showMsg) {
            data.isLimitExceed = true;
            this.showMessage(`Oops! You don't have enough storage.`, 'warning');
          }
        }
        return null;
      }),
      shareReplay(1),
      catchError(err => throwError(() => this.handleErrorMessages(err)))
    );
  }

  updateUserDetails(user: any = this.user): void {
    this.localService.setDataInLocalStorage(
      {
        key: 'kaust-web-user',
        value: user
      },
    );
    this.setUserDetails(user);
  }

  public get isServer(): boolean {
    return !this.localService.isBrowser;
  }

  public get isBrowser(): boolean {
    return this.localService.isBrowser;
  }

  private getBookmarkTimestamp(): number {
    return new Date().valueOf();
  }

  private sortBookmarkList(items: any[] = []): any[] {
    return items?.sort((a: any, b: any) => b.id - a.id);
  }

  private getMapBookmarkList(items: any[] = []): any[] {
    return (this.sortBookmarkList(items))?.map((i: any) => {
      i.bookmarkAt = this.getBookmarkTimestamp();
      return i;
    });
  }
}
