import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";
import {DATASETS, PROJECTS} from "@app/core/constants/routes";

export function checkDatasetImageAndRedirectionHandler(imageSourceObserver: BehaviorSubject<boolean>, router: Router, url: string) {
  if (imageSourceObserver.value &&
    (
      (location.href.includes(DATASETS) && location.href.includes('/edit')) ||
      (location.href.includes(PROJECTS) && location.href.includes('/inference')) ||
      (location.href.includes(PROJECTS) && location.href.includes('/batch-inference'))
    )
  ) {
    window?.open(url, '_blank');
  } else {
    router.navigateByUrl(url);
  }
}
