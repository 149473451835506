import {IUserBundle, IBundle, IUserStats} from "@app/core/interfaces/IBundle";

export class Bundle implements IBundle {
  cost: number = 0;
  deletedAt?: Date;
  maxImages: number = 0;
  deployments: number = 0;
  trainingDiscount: number = 0;
  inferenceDiscount: number = 0;
  cloningRoyalty: number = 0;
  privateDatasets: number = 0;
  privateProjects: number = 0;
  isActive: boolean = true;
  name: string | null = null;
  createdAt: Date | null = null;
  updatedAt: Date | null = null;
  canAccessApis: boolean = false;
  canCloneProject: boolean = false;
  canCloneDataset: boolean = false;
  isSystemCreated: boolean = false;
  modelDownloadCost: number = 0;
  activeLearningCost: number = 0;
  smartAnnotationsCost: number = 0;
  allowsCollaborators: boolean = false;
  allowsModelVersioning: boolean = false;
  allowsCustomTrainingConfig: boolean = false;
  map: boolean = false;
  constructor(values?: IBundle) {
    if (values && Object.keys(values)?.length) {
      Object.assign(this, values);
    }
  }
}

export class UserBundle implements IUserBundle {
  deletedAt?: Date;
  active: boolean = false;
  id: number | null = null;
  userId: number | null = null;
  bundle: IBundle | null = null;
  createdAt: Date | null = null;
  updatedAt: Date | null = null;
  bundleId: number | null = null;
  isLimitExceeded: boolean = false;
  nextBillingDate: Date | null = null;
  bundlePurchaseDate: Date | null = null;

  constructor(values?: IUserBundle) {
    if (values && Object.keys(values)?.length) {
      Object.assign(this, values);
      if (values.bundle?.id) {
        this.bundle = new Bundle(values.bundle);
      }
    }
  }
}

export class UserStats implements IUserStats {
  uploadedImagesCount: number = 0;
  privateDatasetsCount: number = 0;
  privateProjectsCount: number = 0;

  constructor(values?: IUserStats) {
    if (values && Object.keys(values)?.length) {
      Object.assign(this, values);
    }
  }
}
