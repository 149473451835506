import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';
import { Router, Event, NavigationEnd } from '@angular/router';

import { DatasetImageUploadService, SharedDataService } from '@app/core/services';

import * as en from '../assets/i18n/en.json';
import * as ar from '../assets/i18n/ar.json';
import { AppSettingConfig } from '@app/app-types';
import { DATASET_ID_BACKGROUND_IMAGE_KEY } from "@app/core/constants/constant-list";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: any) {
    return this.sharedDataService.isDatasetImageUploadingInCurrentAppInstance()
      ? confirm('Are you sure you want to leave the screen without waiting the dataset images saved?')
      : true;
  }

  @HostListener('window:unload', ['$event'])
  windowUnloadHandler(event: any) {
    this.ngOnDestroy();
  }

  private _onDestroy$: Subject<void> = new Subject<void>();
  currentRoute: string = "/";

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private translate: TranslateService,
    public sharedDataService: SharedDataService,
    private readonly _datasetImageUploadService: DatasetImageUploadService,
  ) {
    this.sharedDataService.initAppInstanceId();
    this.translate.setTranslation('en', en, true);
    this.translate.setTranslation('ar', ar, true);
    this.sharedDataService.appConfigSource.pipe(
      takeUntil(this._onDestroy$)
    ).subscribe((value: any) => {
      const main = document.body;
      if (value.theme == 'dark') {
        main.classList.add(value.theme)
      } else {
        main.classList.remove('dark')
      }
    })
    this.sharedDataService.appConfig$
      .pipe(
        takeUntil(this._onDestroy$)
      ).subscribe((appConfig: AppSettingConfig) => {
        const lang = this.sharedDataService.localService.get('language', false)?.code?.toLowerCase() || 'en';
        const code = lang || 'en';
        if (code != this.translate.currentLang?.toLowerCase()) {
          this.translate.setDefaultLang(code);
          this.translate.use(code);
        }
        if (this.document.documentElement.lang?.toLowerCase() !== code) {
          this.document.documentElement.lang = code;
        }
        const layoutDirection = code == 'ar' ? 'rtl' : 'ltr';
        if (this.document.documentElement.dir?.toLowerCase()) {
          this.document.documentElement.dir = layoutDirection;
        }
        const hasArabicClass = this.document.body.classList.contains('ar-lang');
        if (layoutDirection === 'rtl') {
          if (this.document.body?.className?.indexOf('rtl-direction') === -1) {
            this.document.body?.classList.add('rtl-direction');
          }
        } else {
          this.document.body?.classList.remove('rtl-direction');
        }
        if (code === 'ar') {
          if (!hasArabicClass) {
            this.document.body.classList.add('ar-lang');
          }
        } else {
          if (hasArabicClass) {
            this.document.body.classList.remove('ar-lang');
          }
        }
      });
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntil(this._onDestroy$)
      ).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Hide progress spinner or progress bar
        if (this.currentRoute != event.url) {
          this.sharedDataService.loadingBarSourceReset.next(true);
          this.currentRoute = event.url;
        }
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    if (this.sharedDataService.currentFileUploadDatasetId$.value) {
      this.sharedDataService.setUploadDatasetId(null);
      this._datasetImageUploadService.clearDataAndObservers();
    }
    if (this.sharedDataService.datasetImageList$.value.length > 0) {
      this.sharedDataService.clearAwsFileListData();
    }
  }
}
