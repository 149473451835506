import {NgModule} from '@angular/core';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {ToastrModule} from "ngx-toastr";

import { BaseResolver } from "@app/core/resolvers/base-resolver";
import {
  AwsService,
  AuthService,
  BaseNetworkService,
  HelperService,
  HttpService,
  MetaTagsService,
  SharedDataService,
  LocalStorageService,
  DatasetImageUploadService
} from "@app/core/services";
import { TimerInterceptor } from "@app/core/interceptors/timer.interceptor";
import {JwtInterceptor} from "@app/core/interceptors/jwt.interceptor";

import { translateBrowserLoaderFactory } from './core/shared/loaders/translate-browser.loader';
import { TranslationLoaderService } from "@app/core/services/translation-loader.service";
import { ConfirmModalDialogModule } from './core/shared/confirmation-modal/confirmation-modal.module';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    TransferHttpCacheModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState]
      }
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      autoDismiss: true,
      enableHtml: true,
      closeButton: true,
      maxOpened: 1,
      resetTimeoutOnDuplicate: true,
      progressBar: false,
      timeOut: 10 * 1000, // 15 milliseconds
      positionClass: 'toast-top-center',
      disableTimeOut: false,
      toastClass: 'kaust-toastr',
      messageClass: 'kaust-toastr-text',
      tapToDismiss: true,
    }),
    ConfirmModalDialogModule,
  ],
  providers: [
    MetaTagsService,
    AwsService,
    HttpService,
    HelperService,
    LocalStorageService,
    BaseNetworkService,
    SharedDataService,
    AuthService,
    BaseResolver,
    TranslationLoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimerInterceptor,
      multi: true
    },
    DatasetImageUploadService,
  ],
  exports: [
    BrowserModule
  ]
})
export class AppSharedModule { }
