import {checkRecordOwner} from "@app/core/shared/helpers/check-project-dataset-owner";

export function checkProjectDatasetPermission(userId: any = null, data: any, checkCreator: boolean = true) {
  if (!data) {
    return false;
  } else if (data?.isPublic) {
    return true;
  } else if (checkCreator && checkRecordOwner(userId, data)) {
    return true;
  } else if (data?.users?.some((u: any) => userId && u.user?.id == userId)) {
    return true;
  }
  return false;
}
