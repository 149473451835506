import {BaseModel} from "@app/core/base/BaseModel";
import {IBundle, IUserStats} from "@app/core/interfaces/IBundle";
import {UserStats, UserBundle} from "@app/core/models/BundlePermission";

export class User extends BaseModel {
  public dob: string | null = null;
  public role: string | null = null;
  public email: string | null = null;
  public apiKey?: string | null = null;
  public position: string | null = null;
  public moreInfo: string | null = null;
  public lastName: string | null = null;
  public firstName: string | null = null;
  public accountType: string | null = null;
  public phoneNumber?: number | null = null;
  public lastActivityAt?: Date | null = null;
  public workingSector: string | null = null;
  public profilePicture?: string | null = null;
  public wallet?: any | null = null;
  public isActiveBundle: boolean = false;
  public isLimitExceeded: boolean = false;
  public userStats: IUserStats | null = new UserStats();
  public userBundle: UserBundle | null = new UserBundle();

  constructor(values?: any) {
    super();
    this.active = false;
    if (values && Object.keys(values)?.length) {
      Object.assign(this, values);
      if (values.userStats && Object.keys(values.userStats).length) {
        this.userStats = new UserStats(values.userStats);
      } else {
        this.userStats = new UserStats();
      }
      if (values.userBundle?.id) {
        this.userBundle = new UserBundle(values.userBundle);
        this.setIsActiveBundle();
      }
    }
  }

  public hasPermission(property: keyof IBundle, propertyType: "boolean" | "number" = "boolean", ignoreLimitExceededCheck: boolean = false): boolean {
    const userStats: any = this.userStats || {};
    const bundle: any = this.userBundle?.bundle || {};
    if (bundle[property] === null) {
      return false;
    }
    if (this.isLimitExceeded && !ignoreLimitExceededCheck) {
      return this.checkPermissionForTrainOrInference(property) ? true : false;
    }
    if (propertyType === "boolean") {
      return bundle[property] === true;
    } else if (propertyType === "number") {
      if (typeof bundle[property] === "number" && bundle[property] === 0) {
        return true;
      }
      return bundle[property] !== null;
    }
    return false;
  }

  public checkLimitExceedByProperty(property: keyof IBundle, quotaProperty: keyof IUserStats): boolean {
    if (this.userBundle?.isLimitExceeded) {
      return true;
    }
    const userStats: any = this.userStats || {};
    const bundle: any = this.userBundle?.bundle || null;
    return userStats && bundle && bundle[property] > 0 && userStats[quotaProperty] > bundle[property] ? true : false;
  }

  public checkLimitReachByProperty(property: keyof IBundle, quotaProperty: keyof IUserStats): boolean {
    if (this.userBundle?.isLimitExceeded) {
      return true;
    }
    const userStats: any = this.userStats || {};
    const bundle: any = this.userBundle?.bundle || null;
    return userStats && bundle && bundle[property] > 0 && userStats[quotaProperty] >= bundle[property] ? true : false;
  }

  public increasePrivateDatasetQuotaCount(count: number = 1): void {
    this.userStats!.privateDatasetsCount += count;
    this.checkForQuotaExceedByStatProperty('privateDatasetsCount', 'privateDatasets');
  }

  public decreasePrivateDatasetQuotaCount(count: number = 1): void {
    this.userStats!.privateDatasetsCount -= count;
    this.checkForQuotaExceedByStatProperty('privateDatasetsCount', 'privateDatasets');
  }

  public decreasePrivateDatasetImagesQuotaCount(count: number = 1): void {
    this.userStats!.uploadedImagesCount -= count;
    this.checkForQuotaExceedByStatProperty('uploadedImagesCount', 'maxImages');
  }

  public increasePrivateProjectQuotaCount(count: number = 1): void {
    this.userStats!.privateProjectsCount += count;
    this.checkForQuotaExceedByStatProperty('privateProjectsCount', 'privateProjects');
  }

  public decreasePrivateProjectQuotaCount(count: number = 1): void {
    this.userStats!.privateProjectsCount -= count;
    this.checkForQuotaExceedByStatProperty('privateProjectsCount', 'privateProjects');
  }

  public getRemainingDatasetImagesCount(): number {
    if (typeof this.userBundle?.bundle?.maxImages == null) { // Checking for no upload image permission
      return 0;
    }
    return (this.userBundle?.bundle?.maxImages || 0) - (this.userStats?.uploadedImagesCount || 0);
  }

  private setIsActiveBundle() {
    /*if (this.userBundle?.isLimitExceeded === false) { // Checking if isLimitExceeded is not set form backend and any of the quota exceed then freeze the user account
      if (
        this.checkIsLimitExceedByStatAndBundleProperty('privateDatasetsCount', 'privateDatasets')
        || this.checkIsLimitExceedByStatAndBundleProperty('privateProjectsCount', 'privateProjects')
        || this.checkIsLimitExceedByStatAndBundleProperty('uploadedImagesCount', 'maxImages')
      ) {
        this.freezeUserAccount();
      }
    }*/
    this.isLimitExceeded = this.userBundle?.isLimitExceeded === true;
    this.isActiveBundle = !this.isLimitExceeded;
  }

  private checkForQuotaExceedByStatProperty(property: keyof IUserStats, bundleProperty: keyof IBundle) {
    if (this.checkIsLimitExceedByStatAndBundleProperty(property, bundleProperty)) {
      this.freezeUserAccount();
    }
  }

  private checkPermissionForTrainOrInference(property: keyof IBundle): boolean {
    const userStats: any = this.userStats || {};
    const bundle: any = this.userBundle?.bundle || {};
    return bundle[property] > 0
      && (
        property === 'inferenceDiscount' ||
        property === 'trainingDiscount'
      )
      && (
        userStats.privateDatasetsCount <= bundle.privateDatasets ||
        userStats.privateProjectsCount <= bundle.privateProjects
      );
  }

  private checkIsLimitExceedByStatAndBundleProperty(property: keyof IUserStats, bundleProperty: keyof IBundle): boolean {
    const bundle: any = this.userBundle?.bundle || {};
    return this.userStats![property] > bundle![bundleProperty];
  }

  private freezeUserAccount(): void {
    this.isLimitExceeded = this.userBundle!.isLimitExceeded = true;
    this.isActiveBundle = false;
  }
}
