export function getFilteredAwsUrl(image: string | null = null): string | null {
  if (!image) {
    return null;
  }
  try {
    const url = new URL(decodeURIComponent(image));
    return `${url.origin}${url.pathname}`;
  } catch (e) {
    return null;
  }
}
