import {Injectable, OnDestroy} from '@angular/core';
import {
  CanLoad, Route, Router, UrlSegment, UrlTree, ActivatedRoute
} from '@angular/router';
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {AuthService} from '../services';
import {HOME} from "@app/core/constants/routes";

@Injectable()
export class AuthGuard implements CanLoad, OnDestroy {
  private _redirectTo: string | null = HOME;
  private _onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.activatedRoute.queryParams
      .pipe(
        takeUntil(this._onDestroy$)
      )
      .subscribe(params => {
        if (params['redirectTo']) {
          this._redirectTo = params['redirectTo'];
        }
      });
  }

  /**
   * The following checks if the access can be provided or not to the current user
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      return this.router.navigateByUrl(this._redirectTo || HOME)
        .then()
        .catch();
    }
    return true;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
