import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ConfirmationModalComponent } from './confirmation-modal.component';
import { ConfirmationDialogService } from './confirmation-modal.servicets';

@NgModule({
  declarations: [
    ConfirmationModalComponent
  ],
  exports: [
    ConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    LazyLoadImageModule,
  ],
  providers: [
    ConfirmationDialogService
  ]
})
export class ConfirmModalDialogModule {
}
