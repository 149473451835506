import {HOME} from "./routes";
import {APP_TITLE} from "@app/core/constants/apis-list";

export const MetaTags = [
  {
    url : HOME,
    title : APP_TITLE,
    keywords : APP_TITLE,
    description : APP_TITLE,
    author : APP_TITLE,
  },
]
