import {
  EventEmitter,
  Injectable,
  Injector,
  NgZone,
  Output,
} from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';


import { User } from '@app/core/models/User';
import { DatasetImageItem } from '@app/app-types';
import { SUCCESS_STATUS } from '@app/core/constants/constant-list';
import { S3_CONFIG, DATASETS_BASE_URL } from '@app/core/constants/apis-list';

import {
  AuthService,
  AwsService,
  HelperService,
  SharedDataService,
} from '@app/core/services';
import { ImageCompressService } from '@app/core/shared/modules/image-compress/image-compress.service';

declare const Buffer: any;

@Injectable({
  providedIn: 'root',
})
export class DatasetImageUploadService {
  public source: string = 'datasets';
  public withFileSize: boolean = true;
  public compressImage: boolean = false;
  public clearFileAfterUploaded: boolean = true;
  public apiBaseUrl: string = DATASETS_BASE_URL;
  public bucketName: string = S3_CONFIG.private || '';
  public stopAllUploadCallbackFn!: Function | undefined;
  public isImageSaved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isImageUploading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public stopAllUploading$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  @Output() fileSaved: EventEmitter<DatasetImageItem> =
    new EventEmitter<DatasetImageItem>();

  private readonly _awsService: AwsService;
  private readonly _helperService: HelperService;
  private readonly _sharedDataService: SharedDataService;

  private readonly _onDestroy$: Subject<void> = new Subject<void>();
  private readonly _unsubAwsFileEvent$: Subject<void> = new Subject<void>();
  private readonly _unsubAwsStopAllEvent$: Subject<void> = new Subject<void>();

  public currentUser: User | null = null;

  constructor(
    injector: Injector,
    private readonly _ngZone: NgZone,
    private readonly _authService: AuthService,
    private readonly _imageCompressService: ImageCompressService
  ) {
    this._awsService = injector.get(AwsService);
    this._helperService = injector.get(HelperService);
    this._sharedDataService = injector.get(SharedDataService);

    this._authService.userSubject
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((user: User | null) => (this.currentUser = user));
    this._sharedDataService.datasetImageList$
      ?.pipe(
        map(
          (files: DatasetImageItem[]) =>
            files.filter(
              (f: DatasetImageItem) =>
                !f.stopUploading && !f.uploading && !f.saved
            )[0] || null
        ),
        takeUntil(this._unsubAwsFileEvent$)
      )
      .subscribe({
        next: (f: DatasetImageItem | null) => {
          if (
            !this.isImageUploading$.value &&
            f &&
            !f.stopUploading &&
            (!this.stopAllUploading$.value || f.resumeUploading)
          ) {
            this.uploadCurrentFile(f);
          }
        },
      });
    this.stopAllUploading$
      .pipe(takeUntil(this._unsubAwsStopAllEvent$))
      .subscribe((bool: boolean) => {
        const awsFileList: DatasetImageItem[] =
          this._sharedDataService.datasetImageList$?.value || [];
        const hasDiffer: boolean = awsFileList.some(
          (f) => f.stopUploading !== bool
        );
        if (hasDiffer) {
          const mapAndUpdateFileList = () => {
            this._sharedDataService.datasetImageList$!.next(
              awsFileList.map((f: DatasetImageItem) => {
                f.progress = 0;
                f.uploading = false;
                f.stopUploading = true;
                f.resumeUploading = false;
                return f;
              })
            );
            if (this.stopAllUploadCallbackFn) {
              this.stopAllUploadCallbackFn();
            }
            this.stopAllUploadCallbackFn = undefined;
          };
          this.setImageLoadingValueInStorage(false);
          if (bool) {
            const uploadingFile: DatasetImageItem | null =
              awsFileList.find((f: DatasetImageItem) => f.uploading) || null;
            if (uploadingFile) {
              this.processStopUploadingFile(uploadingFile);
              setTimeout(() => {
                mapAndUpdateFileList();
              }, 200);
            } else {
              mapAndUpdateFileList();
            }
          } else {
            mapAndUpdateFileList();
          }
        }
      });
  }

  clearData(): void {
    this._sharedDataService.clearAwsFileListData();
    this._sharedDataService.setUploadDatasetId(null);
  }

  clearObservers(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
    this._unsubAwsFileEvent$.next();
    this._unsubAwsFileEvent$.complete();
    this._unsubAwsStopAllEvent$.next();
    this._unsubAwsStopAllEvent$.complete();
  }

  clearDataAndObservers() {
    this.clearObservers();
    this.clearData();
  }

  uploadCurrentFile(f: DatasetImageItem) {
    if (
      !this._sharedDataService.currentFileUploadDatasetId$.value &&
      f.sourceId
    ) {
      this._sharedDataService.setUploadDatasetId(f.sourceId);
    }
    if (f?.file) {
      if (!f.fileSize) {
        f.fileSize = f.file.size / 1000; // size in KB
      }
      // Getting image dimensions and subscribing AWS events
      if (this.isJsonFile(f)) {
        if (!f.uploading && !f.stopUploading && f.progress === 0) {
          this.uploadFile(f);
        }
      } else {
        this._helperService.getImageDimensions(f.file, (dimensions: any) => {
          f.width = dimensions.width;
          f.height = dimensions.height;
          if (
            !f.uploading &&
            !f.stopUploading &&
            f.progress === 0 &&
            f.width !== null
          ) {
            this.uploadFile(f);
          }
        });
      }
    } else if (this.withFileSize && f?.id && !f?.fileSize) {
      // Running process in a separate thread
      this._ngZone.runOutsideAngular(() => {
        this._awsService.checkObjectExist(this.getFileKey(f)).then((value: any) => {
          // Running save file size in Main thread to run change detection
          this._ngZone.run(() => {
            f.fileSize = (value?.ContentLength || 0) / 1000; // size in KB
          });
        });
      });
    }
  }

  /*
   * Upload new file on AWS S3 bucket
   *
   * @param {file} File | null = By default sets current uploaded file
   * */
  uploadFile(f: DatasetImageItem) {
    if (f.sourceId) {
      // Run validation checks and checking file isn't in waiting it's turn to start upload
      if (f.file) {
        this.setImageLoadingValueInStorage(true);
        // Resetting abort flag
        if (f.stopUploading) {
          f.stopUploading = false;
        }
        f.uploading = true;
        this.updateElementInAwsFileList(f);
        // Running file upload process in outside of the main Angular thread to avoid performance issues
        this._ngZone.runOutsideAngular(() => {
          // Uploading image to S3 bucket in a separate thread to avoid performance issue
          if (this.compressImage && !this.isJsonFile(f.file)) {
            this._imageCompressService
              .compressImageFile(f.file as File, 100, 50)
              .then((newFile: File) => {
                f.file = newFile;
                this.updateElementInAwsFileList(f);
                this.processUploadFile(f);
              })
              .catch((reason) => { });
          } else {
            this.updateElementInAwsFileList(f);
            this.processUploadFile(f);
          }
        });
      } /* else if (!f.file && f.fileUri) {
        f.uploading = true;
        f.file = this._helperService.dataURLtoFile(f.fileUri, f.formattedName);
        if (f.xmlFileDataUri && !f.xmlFile) {
          f.xmlFile = this._helperService.dataURLtoFile(f.xmlFileDataUri, f.formattedName);
        }
        this.uploadFile(f);
      }*/
    }
  }

  /*
   * verifying the json file type
   * */
  isJsonFile(file: any = null): boolean {
    return file && file?.name?.includes('json');
  }

  /*
   * Stop file upload event handler
   * */
  stopUploadHandler(
    f: DatasetImageItem,
    index?: number,
    ignoreUpdateList?: boolean
  ): void {
    f.resumeUploading = false;
    this.processStopUploadingFile(f);
    this.setImageLoadingValueInStorage(false);
    this.updateElementInAwsFileList(f, index);
  }

  /*
   * Saving file to the server
   * @param {geo json File} - instance of the file
   * */
  saveFileJson(geoJsonFile: any): void {
    if (geoJsonFile) {
      if (this.apiBaseUrl) {
        this.updateElementInAwsFileList(geoJsonFile);
        const formDataMap: FormData = new FormData();
        formDataMap.append('file', geoJsonFile?.file);
        formDataMap.append('id', geoJsonFile?.sourceId);

        const url: string = `${this.apiBaseUrl}/${geoJsonFile.sourceId}/maps`;
        this._authService
          .requestEntity(
            'POST',
            url,
            formDataMap,
            this._authService.multiPartFormDataHeaders
          )
          .pipe(takeUntil(this._onDestroy$))
          .subscribe({
            next: (res: any) => {
              // Checking for xml file and emitting uploaded and save event
              if (res?.status === SUCCESS_STATUS) {
                this.clearUploadStatus(geoJsonFile);
                geoJsonFile.file = null;
                geoJsonFile.uploading = false;
                geoJsonFile.stopUploading = true;
                geoJsonFile.resumeUploading = false;
                geoJsonFile.isCategoryMap = true;
                if (!geoJsonFile.saved) {
                  geoJsonFile.saved = true;
                }
                if (!this.isImageSaved$.value) {
                  this.isImageSaved$.next(true);
                }
                this._ngZone.run(() => {
                  this.setImageLoadingValueInStorage(false);
                  this.updateFileListAndTotalAfterFileSaved(geoJsonFile);
                  setTimeout(() => {
                    this.fileSaved.emit({ ...geoJsonFile });
                  });
                });
              }
            },
            error: (err) => {
              console.log(
                'failed to save images on kaust',
                geoJsonFile.name,
                err
              );
            },
          });
      } else {
        this.updateElementInAwsFileList(geoJsonFile);
        this.addIncrementInTotalUploadedFilesCount();
      }
    }
  }

  /*
   * Saving file to the server after upload done on AWS S3
   *
   * @param {imageUrl} string - Uploaded image S3 url
   * @param {awsFile} DatasetImageItem - instance of the aws image file
   * @param {fileSize} number - Size of the image file in KB
   * */
  saveFile(imageUrl: string, awsFile: any, fileSize: number = 0): void {
    if (imageUrl && awsFile.sourceId) {
      if (this.apiBaseUrl) {
        const formData: FormData = new FormData();
        formData.append('image', imageUrl);
        formData.append('id', `${awsFile.sourceId}`);
        formData.append('width', `${awsFile.width || 0}`);
        formData.append('height', `${awsFile.height || 0}`);
        formData.append('imageName', awsFile.formattedName || '');
        formData.append('imageSize', `${fileSize || awsFile.fileSize || 0}`);

        if (awsFile.xmlFile) {
          formData.append(
            'file',
            awsFile.xmlFile,
            awsFile.xmlFile.name || new Date().valueOf().toString()
          );
        }
        const url: string = `${this.apiBaseUrl}/${awsFile.sourceId}/images`;
        this._authService
          .requestEntity(
            'POST',
            url,
            formData,
            this._authService.multiPartFormDataHeaders
          )
          .pipe(takeUntil(this._onDestroy$))
          .subscribe({
            next: (res: any) => {
              // Checking for xml file and emitting uploaded and save event
              if (res?.status === SUCCESS_STATUS) {
                awsFile.file = null;
                awsFile.awsImageUrl = imageUrl;
                /**
                 * Removing the XML file check
                 * NOTE: commented this code-block to differentiate between xml and image files
                 */
                // if (awsFile.xmlFile) {
                //   awsFile.xmlFile = null;
                // }
                if (!awsFile.saved) {
                  awsFile.saved = true;
                }
                if (!this.isImageSaved$.value) {
                  this.isImageSaved$.next(true);
                }
                this.setImageLoadingValueInStorage(false);
                this.updateFileListAndTotalAfterFileSaved(awsFile);
                setTimeout(() => {
                  this.fileSaved.emit({ ...awsFile });
                });
              }
            },
            error: (err) => {
              console.log(
                'failed to save images on kaust',
                imageUrl,
                awsFile.name,
                err
              );
            },
          });
      } else {
        this.updateElementInAwsFileList(awsFile);
        this.addIncrementInTotalUploadedFilesCount();
      }
    }
  }

  stopAllInProgressFile(bool: boolean, cb?: Function): void {
    if (this.stopAllUploading$.value !== bool) {
      if (cb) {
        this.stopAllUploadCallbackFn = cb;
      }
      this.stopAllUploading$.next(bool);
    }
  }

  /*
   * Deleting selected file from S3 and Server
   * */
  deleteDatasetImage(f: DatasetImageItem, cb?: Function): void {
    if (this.isJsonFile(f)) {
      this.processDeleteImageInAPIServer(f, cb);
    } else {
      if (f.sourceId && f.localId) {

        // Running process in a separate thread
        this._ngZone.runOutsideAngular(() => {
          // Checking file exists
          this._awsService.checkObjectExist(this.getFileKey(f))
            .then((value: any) => {
              // Checking for file size
              if (f.fileSize && value.ContentLength) {
                f.fileSize = value.ContentLength / 1000; // size in KB
              }
              // Deleting file from AWS S3 bucket
              this._awsService
                .deleteObjects({
                  Key: this.getFileKey(f)
                })
                .then((value) => {
                  // Running delete process in Main thread to run change detection
                  this._ngZone.run(() => {
                    this.processDeleteImageInAPIServer(f, cb);
                  });
                })
                .catch((reason) => {
                  console.error(reason);
                });
            })
            .catch((reason) => {
              this._ngZone.run(() => {
                this.processDeleteImageInAPIServer(f, cb);
              });
            });
        });
      } else {
        this.removeCurrentFileFromList(f);
      }
    }
  }

  /*
   * Toggle image check handler
   * */
  toggleImageCheck(index: number, f: DatasetImageItem, checked: boolean): void {
    f.checked = checked;
    this.updateElementInAwsFileList(f, index);
  }

  resumeFileUploading(index: number, f: DatasetImageItem, bool: boolean): void {
    if (bool && f.stopUploading) {
      f.uploading = false;
      f.stopUploading = false;
      f.resumeUploading = true;
      if (this.isImageUploading$.value) {
        this.isImageUploading$.next(false);
      }
      setTimeout(() => {
        this.updateElementInAwsFileList(f, index);
      }, 100);
    }
  }

  public toggleBulkImageCheck(checked: boolean): void {
    this._sharedDataService.datasetImageList$!.next(
      this._sharedDataService.datasetImageList$?.value?.map(
        (f: DatasetImageItem) => {
          f.checked = checked;
          return f;
        }
      ) || []
    );
  }

  public updateFilesInLocalStorage(
    awsFileList: DatasetImageItem[] = this._sharedDataService.datasetImageList$
      ?.value || []
  ): void { }

  /*
   * Resets file ids and uploaded event
   * */
  private clearFileId(f: DatasetImageItem): void {
    if (f.saved) {
      f.fileId = null;
    }
  }

  /*
   * Clear file upload progress
   * */
  private clearUploadStatus(f: DatasetImageItem): void {
    f.progress = 0;
  }

  /*
   * AWS S3 error handler
   *
   * @param {code} string - Error Code
   * */
  private handleAWSErrorByCode(f: DatasetImageItem, code: string): void {
    switch (code) {
      case 'RequestAbortedError':
        this.clearUploadStatus(f);
        if (f.stopUploading) {
          f.stopUploading = true;
        }
        break;
    }
  }


  /*
   * Process delete image API
   * */
  private processDeleteImage(f: DatasetImageItem, cb?: Function): void {
    const url = `${this.apiBaseUrl}/${f.sourceId}/${this.isJsonFile(f) ? 'maps-bulk-delete' : 'images-bulk-delete'}`;
    const totalFilesSize: string = `${f.file ? f.file!.size / 1000 : f.fileSize || 0
      }`;
    let bodyImages = {
      totalFilesSize,
      imageNames: [f.formattedName],
    }
    let bodyMaps = {
      totalFilesSize,
      mapNames: [f.formattedName],
    }
    this._authService
      .requestEntity('DELETE', url, this.isJsonFile(f) ? bodyMaps : bodyImages)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res: any) => {
        if (res?.status == 200) {
          // Emit delete event and resetting data
          f.saved = false;
          if (cb) {
            cb(f.localId);
          }
          f.fileId = null;
          this.removeCurrentFileFromList(f);
        }
      });
  }

  /*
   * Decodes file URL according to the current formatted file name and S3 bucket directory
   *
   * @returns {string}
   * */
  private getFileKey(f: DatasetImageItem): string {
    return decodeURIComponent(
      `${this.source}/${f.sourceId}/${f.formattedName}`
    );
  }

  /*
   * Process Upload file on AWS S3 bucket and API Server
   *
   * @param {file} File - Instance of the file
   *
   * */
  private processUploadFile(f: DatasetImageItem): void {
    if (f.file) {
      if (this.isJsonFile(f)) {
        this.saveFileJson(f);
      } else {
        // Generating file path
        const filePath = `${this.source}/${f.sourceId}/${f.formattedName}`;
        const params: any = {
          Body: f.file,
          Key: filePath,
          Bucket: this.bucketName,
          ContentType: f.file.type,
          ContentDisposition: 'inline',
          Metadata: {
            // Values must be in string format
            width: `${f?.width || 0}`,
            height: `${f?.height || 0}`,
          },
        };
        this._awsService.putObjectRequest(params).subscribe(
          {
            next: (progress: any) => {
              // Updating progress in Angular thread to run detect changes cycle
              this._ngZone.run(() => {
                f.progress = progress;
                this.updateElementInAwsFileList(f);
              });
              if (progress === 100) {
                this._ngZone.run(() => {
                  const fileSize: number = (f.file?.size || 0) / 1000; // size in KB
                  const imageUrl = `https://${this.bucketName}/${filePath}`;
                  if (f.uploading) {
                    f.uploading = false;
                  }
                  if (!f.xmlFile) {
                    f.awsImageUrl = imageUrl;
                    f.saved = true;
                  } else if (this.clearFileAfterUploaded) {
                    f.file = null;
                  }
                  this.updateElementInAwsFileList(f);
                  this.saveFile(imageUrl, f, fileSize);
                });
              }
            },
            error: (err: any) => {
              this._ngZone.run(() => {
                f.stopUploading = true;
                if (f.uploading) {
                  f.uploading = false;
                  this.setImageLoadingValueInStorage(false);
                }
                // Resetting file status and clear previous id
                this.clearUploadStatus(f);
                this.clearFileId(f);
                this.updateElementInAwsFileList(f);
              });
            },
            complete: () => {
              this._ngZone.run(() => {
                // Clear upload status
                this.clearUploadStatus(f);
                this.updateElementInAwsFileList(f);
              });
            }
          });
        // Checking and setting flags for the stop all uploading to avoid upload instantly
        if (f.stopUploading) {
          f.stopUploading = false;
          this.updateElementInAwsFileList(f);
        }
      }
    }
  }

  /*
   * Process delete image file in API server
   * */
  private processDeleteImageInAPIServer(f: DatasetImageItem, cb?: Function) {
    // Checking if file is saved on server and calling delete image api
    if (f.saved && this.apiBaseUrl) {
      this.processDeleteImage(f, cb);
    } else {
      // Deleting file locally if not saved yet in API server
      this.removeCurrentFileFromList(f, false);
    }
  }

  private removeCurrentFileFromList(
    f: DatasetImageItem,
    refreshUserStats: boolean = true
  ): DatasetImageItem[] {
    const awsFileList: DatasetImageItem[] = [
      ...(this._sharedDataService.datasetImageList$?.value || []),
    ];
    const index: number = awsFileList.findIndex(
      (af: DatasetImageItem) => f.localId == af.localId
    );
    if (index > -1) {
      awsFileList.splice(index, 1);
      this.updateFilesInLocalStorage(awsFileList);
      this._sharedDataService.datasetImageList$!.next([...awsFileList]);
      this._sharedDataService.updateDatasetTotalUploadedFilesCount(1);
      if (awsFileList.length === 0) {
        this.clearData();
      }
      if (refreshUserStats) {
        this.updateUploadImagesCountInUserStats(-1);
      }
    }
    return awsFileList;
  }

  private updateUploadImagesCountInUserStats(count: number): void {
    this.currentUser!.userStats!.uploadedImagesCount += count;
    this.currentUser = new User({ ...this.currentUser });
    this._authService.user.userStats!.uploadedImagesCount =
      this.currentUser.userStats!.uploadedImagesCount;
    this._authService.updateUserDetails();
  }

  private updateElementInAwsFileList(
    element: DatasetImageItem,
    index?: number
  ): void {
    const awsFileList: DatasetImageItem[] = [
      ...(this._sharedDataService.datasetImageList$?.value || []),
    ];
    const idx: number =
      index !== undefined
        ? index
        : awsFileList.findIndex(
          (f: DatasetImageItem) => f.localId == element.localId
        );
    if (idx > -1) {
      awsFileList[idx] = element;
      this.updateFilesInLocalStorage(awsFileList);
      this._sharedDataService.datasetImageList$!.next(awsFileList);
    }
  }

  private addIncrementInTotalUploadedFilesCount(): void {
    this._sharedDataService.updateDatasetTotalUploadedFilesCount(
      1,
      (total: number) => {
        setTimeout(() => {
          if (this._sharedDataService.datasetImageList$?.value?.length === 0) {
            this.clearData();
          }
        }, 200);
      }
    );
  }

  private setImageLoadingValueInStorage(bool: boolean = false): void {
    if (this.isImageUploading$.value !== bool) {
      this.isImageUploading$.next(bool);
    }
  }

  private updateFileListAndTotalAfterFileSaved(
    awsFile: DatasetImageItem
  ): void {
    this.removeCurrentFileFromList(awsFile, false);
    this.updateUploadImagesCountInUserStats(1);
  }

  private processStopUploadingFile(f: DatasetImageItem): void {
    f.progress = 0;
    f.uploading = false;
    f.stopUploading = true;
    f.resumeUploading = false;
  }
}
