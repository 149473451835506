import {Injectable, Output, EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {v4 as uuid} from 'uuid';

import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { AppSettingConfig, AwsImageOption } from '@app/app-types';
import {
  APP_CONFIG_KEY,
  DATASET_IMAGE_COUNT_KEY,
  DATASET_ID_BACKGROUND_IMAGE_KEY,
  DATASET_UPLOADED_IMAGE_COUNT_KEY,
  INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY,
  BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY,
} from '@app/core/constants/constant-list';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  public loadingBarSource = new BehaviorSubject(false);
  private formSubmitButtonSource = new BehaviorSubject(false);
  public loadingBarSourceReset = new BehaviorSubject(false);
  public appConfigSource: BehaviorSubject<AppSettingConfig> = new BehaviorSubject<AppSettingConfig>(this.getDefaultAppConfig());
  appConfig$ = this.appConfigSource.asObservable();

  public hideNavBar$ = new BehaviorSubject(false);
  public inferenceImageUploadingSource = new BehaviorSubject(false);
  public batchInferenceImageUploadingSource = new BehaviorSubject(false);

  public datasetTotalSelectedFilesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public datasetTotalUploadedFilesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public appInstanceId$: BehaviorSubject<null | string> = new BehaviorSubject<null | string>(null);
  public datasetImageUploadAppInstanceId$: BehaviorSubject<null | string> = new BehaviorSubject<null | string>(null);
  public currentFileUploadDatasetId$: BehaviorSubject<null | number> = new BehaviorSubject<number | null>(null);
  public datasetImageList$: BehaviorSubject<AwsImageOption[]> = new BehaviorSubject<AwsImageOption[]>([]);
  public isAnnotationOpenBoolean = new BehaviorSubject(false);
  public currentLanguage: any = 'en';

  @Output() toggleShowWidget: EventEmitter<boolean> = new EventEmitter<boolean>(true);

  constructor(
    public httpService: HttpService,
    public localService: LocalStorageService,
  ) {
    this.datasetTotalSelectedFilesCount$.next(
      this.localService.get(DATASET_IMAGE_COUNT_KEY) || 0
    );
    this.datasetTotalUploadedFilesCount$.next(
      this.localService.get(DATASET_UPLOADED_IMAGE_COUNT_KEY) || 0
    );
    this.currentFileUploadDatasetId$.next(
      this.localService.get(DATASET_ID_BACKGROUND_IMAGE_KEY) || null
    );
    this.appConfigSource.next(
      this.localService.get(APP_CONFIG_KEY) || this.getDefaultAppConfig()
    );
    this.inferenceImageUploadingSource.next(
      this.localService.get(INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY) || false
    );
    this.batchInferenceImageUploadingSource.next(
      this.localService.get(BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY) || false
    );
    // Setting user language
    const lang = this.localService.get('language', false)?.code?.toLowerCase() || 'en';
    this.setUserLanguage(lang || 'en');
    this.currentLanguage = lang || 'en';
  }

  /**
   * the following method is used to emit for loading bar
   * @param status
   */
  showLoadingBar(status: boolean) {
    setTimeout(() => {
      this.loadingBarSource.next(status);
    }, 150);
  }

  /**
   * the following method is used to emit for loading bar
   * @param submit
   */
  changeFormSubmitStatus(submit: boolean) {
    setTimeout(() => {
      this.formSubmitButtonSource.next(submit);
    }, 150);
  }

  /**
   * the following method is used to set the app theme mode
   * @param config
   */
  setAppConfig(config: AppSettingConfig = this.getDefaultAppConfig()): void {
    if (JSON.stringify(this.appConfigSource.value) !== JSON.stringify(config)) {
      this.appConfigSource.next(config);
      this.localService.set(
        {
          key: APP_CONFIG_KEY,
          value: config,
        }
      );
    }
  }

  getDefaultAppConfig(): any {
    const lang = this.localService.get('language', false)?.code?.toLowerCase() || 'en';
    return <AppSettingConfig>{
      theme: 'dark',
      locale: lang || 'en',
      isSideBarCollapse: false,
      language: this.getDefaultLanguage(),
      layoutDirection: lang == 'ar' ? 'rtl' : 'ltr',
    };
  }

  /**
   * the following method is used to set user language
   * @param language
   */
  setUserLanguage(language: any = null): void {
    if (language?.code && ['en', 'ar'].indexOf(language.code.toLowerCase()) === -1) {
      language = this.getDefaultLanguage();
    }
    const appConfig = { ...this.appConfigSource.value };
    appConfig.language = language;
    appConfig.locale = language.code;
    this.setAppConfig(appConfig);
  }

  getTranslatedText(data: any, key: string) {
    if (!data) {
      return '';
    }
    const lang = this.localService.get('language', false)?.code?.toLowerCase() || 'en';
    const locale = lang || 'en';
    const capitalizeLocale = locale.charAt(0).toUpperCase() + locale.slice(1);
    const text = data[`${key}${capitalizeLocale}`];
    return text || data[key] || data[`${key}En`] || '';
  }

  public setUploadDatasetId(id: number | null = null): void {
    this.currentFileUploadDatasetId$.next(id);
    if (id && !this.datasetImageUploadAppInstanceId$.value) {
      this.datasetImageUploadAppInstanceId$.next(
        this.appInstanceId$.value
      );
    }
    if (!id) {
      this.datasetImageUploadAppInstanceId$.next(null);
      this.localService.remove(DATASET_ID_BACKGROUND_IMAGE_KEY);
      this.resetDatasetCounts();
    } else if (id && this.localService.get(DATASET_ID_BACKGROUND_IMAGE_KEY) != id) {
      this.localService.set({
        value: id,
        key: DATASET_ID_BACKGROUND_IMAGE_KEY,
      })
    }
  }

  public updateDatasetTotalUploadedFilesCount(count: number, cb?: Function): void {
    const total: number = this.datasetTotalUploadedFilesCount$.value + count;
    this.localService.set({
      value: total,
      key: DATASET_UPLOADED_IMAGE_COUNT_KEY,
    });
    this.datasetTotalUploadedFilesCount$.next(total);
    if (cb) {
      cb(total);
    }
  }

  public updateDatasetTotalSelectedFilesCount(count: number): void {
    this.localService.set({
      value: count,
      key: DATASET_IMAGE_COUNT_KEY,
    });
    this.datasetTotalSelectedFilesCount$.next(count);
  }

  public resetDatasetCounts(): void {
    this.datasetTotalSelectedFilesCount$.next(0);
    this.datasetTotalUploadedFilesCount$.next(0);
    this.localService.remove(DATASET_IMAGE_COUNT_KEY);
    this.localService.remove(DATASET_UPLOADED_IMAGE_COUNT_KEY);
  }

  public clearAwsFileListData(): void {
    this.datasetImageList$!.next([]);
  }

  public initAppInstanceId(): void {
    this.appInstanceId$.next(uuid());
  }

  public isDatasetUploadingImageInDifferentTab(): boolean {
    return this.currentFileUploadDatasetId$.value && !this.datasetImageUploadAppInstanceId$.value ? true : false;
  }

  public isDatasetImageUploadingInCurrentAppInstance(): boolean {
    return this.appInstanceId$.value && this.datasetImageUploadAppInstanceId$.value
      && this.currentFileUploadDatasetId$.value && this.appInstanceId$.value === this.datasetImageUploadAppInstanceId$.value ? true : false;
  }

  private getDefaultLanguage(): any {
    const lang = this.localService.get('language', false)?.code?.toLowerCase() || 'en';
    return {
      id: null,
      code: lang || 'en',
    };
  }
  getUniqueListBy(arr: any, key: any) {
    return [...new Map(arr.map((item: any) => [item[key], item])).values()]
  }
}
