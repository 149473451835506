'use strict';
import { environment } from "src/environments/environment";
export const apiConfig = environment
export const APP_TITLE: string = apiConfig.config.title;
export const APP_ENVIRONMENT: string = apiConfig.config.environment;
export const SERVER_URL: string = apiConfig.config.apiUrl.backendUrl;
export const FE_URL: string = apiConfig.config.apiUrl.frontendUrl;
export const CMS_URL: string = apiConfig.config.apiUrl.cmsUrl;
export const TAWK_TO_URL: string = apiConfig.config.apiUrl.tawkToUrl;
export const TAWK_TO_PROPERTY_ID: string = apiConfig.config.apiUrl.propertyId;
export const TAWK_TO_WIDGET_ID: string = apiConfig.config.apiUrl.widgetId;
export const GOOGLE_MAP_API_KEY: string = apiConfig.config.googleMapAPIKey;
export const ACCESS_TOKEN_MAP_BOX: string = apiConfig.config.accessTokenMapBox;
export const STYLE_MAP_BOX_LIGHT: string = apiConfig.config.styleMapBoxLight;
export const STYLE_MAP_BOX_BLACK: string = apiConfig.config.styleMapBoxBlack;
export const ADMIN_BASE_URL: string = `${SERVER_URL}admin`;
export const S3_CONFIG = apiConfig.s3BucketConfig;

export const CUSTOMER_AUTH_BASE_URL: string = `${SERVER_URL}customer/auth`;

export const LOGIN: string = `${CUSTOMER_AUTH_BASE_URL}/login`;
export const LOGOUT: string = `${CUSTOMER_AUTH_BASE_URL}/logout`;
export const SIGNUP: string = `${CUSTOMER_AUTH_BASE_URL}/register`;
export const RESET_PASSWORD: string = `${CUSTOMER_AUTH_BASE_URL}/reset-password`;
export const UPDATE_PASSWORD: string = `${CUSTOMER_AUTH_BASE_URL}/update-password`;
export const VERIFY_RESET_TOKEN: string = `${CUSTOMER_AUTH_BASE_URL}/verify-reset-password-link`;

export const CUSTOMER_BASE_URL: string = `${SERVER_URL}customers`;
export const ALL_USER_BASE_URL: string = `${CUSTOMER_BASE_URL}/all`;
export const GET_STORAGE_DETAILS: string = `${CUSTOMER_BASE_URL}/{id}/get-storage`;

export const DATASETS_BASE_URL: string = `${SERVER_URL}datasets`;
export const PROJECTS_BASE_URL: string = `${SERVER_URL}projects`;
export const TAGS_BASE_URL: string = `${SERVER_URL}customer/tags/all`;
export const CONTACT_US: string = `${SERVER_URL}support/contact-us`;
export const JOIN_US: string = `${SERVER_URL}support/join-us`;
export const LANDING_PAGE_BASE_URL: string = `${SERVER_URL}landing-page`;
export const PROJECT_LABELS_BASE_URL: string = `${PROJECTS_BASE_URL}/{id}/labels`;
export const PROJECTS_ADVANCE_MAPPING_DETAILS_URL: string = `${PROJECTS_BASE_URL}/{id}/advance-mapping`;
export const PROJECT_DATASETS_ANNOTATIONS: string = `${PROJECTS_BASE_URL}/annotations/{id}`;
export const PROJECT_MAP_CONFIGURATIONS: string = `${PROJECTS_BASE_URL}/save-configurations/{id}`;

export const USER_STORIES_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/our-story/all`;
export const DATASET_CATEGORIES_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/dataset-categories/all`;
export const HERO_IMAGE_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/hero-image`;

export const PUBLIC_DATASETS_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/datasets`;
export const PUBLIC_DATASETS_DETAIL_URL: string = `${DATASETS_BASE_URL}/public`;

export const PUBLIC_PRODUCTS_BASE_URL: string = `${LANDING_PAGE_BASE_URL}/product`;

export const BLOGS_BASE_URL: string = `${SERVER_URL}cms/blogs`;
export const BLOGS_TAGS_URL: string = `${ADMIN_BASE_URL}/blog/tags`;

export const TEAMS_BASE_URL: string = `${SERVER_URL}company/team/founders/all`;
export const EMPLOYEES_BASE_URL: string = `${SERVER_URL}company/team/employees/all`;

export const FAQ_CATEGORIES_BASE_URL: string = `${ADMIN_BASE_URL}/faq-categories/all`;
export const FAQS_BASE_URL: string = `${ADMIN_BASE_URL}/faqs/all`;

export const CONFIGURATION_BASE_URL: string = `${ADMIN_BASE_URL}/config/`;

export const ABOUT_BASE_URL: string = `${CONFIGURATION_BASE_URL}about/intro/1`;

/**
 * Dataset Health Check APIs configuration
 */
export const PUBLIC_DATASETS_STATS: string = `${LANDING_PAGE_BASE_URL}/datasets/health-check/`;

// Dataset Annotation, Label and Smart Selection APIs
export const DATASETS_ANNOTATION_BASE_URL: string = `${DATASETS_BASE_URL}/annotation`;
export const DATASETS_IMAGES_BASE_URL: string = `${DATASETS_BASE_URL}/{id}/images`;
export const DATASETS_LABEL_BASE_URL: string = `${DATASETS_ANNOTATION_BASE_URL}/labels`;
export const DATASET_GET_SMART_SELECTION: string = `${DATASETS_BASE_URL}/{id}/smart-annotation`;
export const DATASET_TRAINED_MODEL_LIST: string = `${DATASETS_BASE_URL}/{id}/get-all-inference-projects`;

// Project Logs
export const PROJECT_LOGS: string = `${SERVER_URL}task`;
export const PROJECT_BATCH_INFERENCE_LOGS: string = `${SERVER_URL}batch-inference/list`;

// Projects datasets training
export const DATASETS_TRAINING: string = `${PROJECTS_BASE_URL}/dataset/training`;

// Credits
export const CREDITS: string = `${SERVER_URL}top-up/all`;
export const INVOICE_EMAIL: string = `${SERVER_URL}top-up/invoice-email/{id}`;
export const USER_CREDITS: string = `${SERVER_URL}top-up/user/{id}`;
export const CREDITS_PLAN: string = `${SERVER_URL}plan/{id}`;
export const CREDITS_PLAN_USER: string = `${SERVER_URL}plan/user/{id}`;
export const CREDITS_RATES: string = `${ADMIN_BASE_URL}/rates/{id}`;

// Spendings & Earnings
export const USER_EARNINGS: string = `${SERVER_URL}top-up/user/{user}/earning`;
export const USER_SPENDINGS: string = `${SERVER_URL}top-up/user/{user}/spending`;

// Project Inference and Detect
export const PROJECT_INFERENCE_DEDUCT: string = `${PROJECTS_BASE_URL}/inference-deduct`;
export const PROJECT_INFERENCE_EXPORT: string = `${PROJECTS_BASE_URL}/export-inference`;
export const PROJECT_INFERENCE_VALIDATE: string = `${PROJECTS_BASE_URL}/inference-validate`;
export const PROJECT_INFERENCE_DETAIL: string = `${PROJECTS_BASE_URL}/{id}/get-inference-result`;
export const PROJECT_INFERENCE_SAVE_RESULT: string = `${PROJECTS_BASE_URL}/{id}/save-inference-result`;
export const PROJECT_INFERENCE_SAVED_IMAGE_COUNT: string = `${PROJECTS_BASE_URL}/{id}/get-infered-image-count-for-export`;
export const PROJECT_WRONGLY_DETECTED_IMAGES: string = `${PROJECTS_BASE_URL}/wrongly_detected_image`;
export const PROJECT_INFERENCE_PREDICT: string = `${apiConfig.config.apiUrl.inferencePredictServerUrl}/:key`;

export const SEND_DOWNLOAD_DATASET: string = `${DATASETS_BASE_URL}/{id}/download`;

// Inference Result & Batch Inference
export const GET_INFERENCE_RESULT: string = `${SERVER_URL}inference/requests/{id}`;
export const BATCH_INFERENCE_BASE_URL: string = `${SERVER_URL}batch-inference`;
export const BATCH_INFERENCE_START: string = `${BATCH_INFERENCE_BASE_URL}/start`;
export const BATCH_INFERENCE_CANCEL: string = `${BATCH_INFERENCE_BASE_URL}/cancel`;
export const BATCH_INFERENCE_EXPORT_RESULT: string = `${BATCH_INFERENCE_BASE_URL}/export-batch-inference-annotations`;
export const BATCH_DETECTION_SUMMARY_EXPORT_RESULT: string = `${BATCH_INFERENCE_BASE_URL}/export-batch-detection-summary`;

// Algorithm Type
// export const GET_ALL_ALGO_TYPE_LIST: string = `${SERVER_URL}algorithm/types/status`;
export const GET_ALL_ALGO_TYPE_LIST: string = `${SERVER_URL}algorithm/types`;
export const GET_ALL_ALGO_TYPE_LIST_BY_ID: string = `${SERVER_URL}algorithm/all`;

// Bundles
export const GET_BUNDLE_DETAILS: string = `${CUSTOMER_BASE_URL}/bundles`;
export const CHANGE_PLAN: string = `${CUSTOMER_BASE_URL}/bundles/change-plan/charge-bundle/`;


// Credit Details
export const GET_CREDIT_DETAILS: string = `${SERVER_URL}credit-details/all`;
export const REQUEST_CREDIT: string = `${SERVER_URL}customer/credit-petition`;

/**
 * Dataset Health Check APIs configuration
 */
export const DATASETS_STATS: string = `${SERVER_URL}datasets/health-check/`;

/**
 * Payment Redirection URL
 */

export const PAYMENT_REDIRECTION_URL: string = `${SERVER_URL}fortpay/payment`; // we need to remove /api/v1

/**
 * saving annotation for maps
 */
export const SAVE_DATASETS_STATE_MAP: string = `${SERVER_URL}datasets/{id}/update-maps`;
export const POST_DATASETS_CONFIG_MAP: string = `${SERVER_URL}datasets/{id}/save-configuration`;
export const GET_DATASETS_CONFIG_ITEMS_MAP: string = `${SERVER_URL}datasets/{id}/get-config-items`;
export const GET_DATASETS_CONFIG_MAP: string = `${SERVER_URL}datasets/{id}/map_configuration`;
export const GET_DATASETS_CONFIG_MAP_SELECTION_AREA: string = `${SERVER_URL}datasets/{id}/map_configuration_selectionarea`;
export const DELETE_DATASETS_CONFIG_MAP_SELECTION_AREA: string = `${SERVER_URL}datasets/{id}/map_configuration`;

export const GET_AWS_SIGNED_URL_FETCH_IMG: string = `${SERVER_URL}s3/get-signed-url`;
export const PATCH_AWS_SIGNED_URL_IMG: string = `${SERVER_URL}s3/put-signed-url/{id}`;
export const DELETE_AWS_SIGNED_URL_PATCH_IMG: string = `${SERVER_URL}s3/delete-signed-url/{id}`;
export const GET_IS_FILE_EXIST: string = `${SERVER_URL}s3/is-file-exist`;
export const DELETE_FILE: string = `${SERVER_URL}s3/delete-file`;
export const DELETE_BULK_FILE: string = `${SERVER_URL}s3/delete-bulk-files`;
export const GET_LIST_OBJECTS: string = `${SERVER_URL}s3/list-Objects`;

/**
 * deleting annotation for maps
 */
export const DELETE_DATASETS_ANNOTATION_MAP: string = `${SERVER_URL}datasets/{id}/maps`;
